import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { FaGithubSquare, FaGithub, FaVimeo } from "react-icons/fa"
import Projects from "./Projects"
const Project = ({description,title,github,url,image,index,github_backend,stack,bullet}) => {
  console.log(bullet)

  return <article className="project">
    {image && (
    <Image fluid={image.childImageSharp.fluid} className="project-img" />
    )}
    <div className="project-info">
      <span className="project-number">0{index + 1}.</span>
      <h3>{title || 'default title'}</h3>
        <p className="project-desc">
          {description || 'Description not available'}
          <p className="project-bullets-desc">
          {bullet || 'Bullets not available'}
          </p> 
        </p>
      <div className="project-stack">
        {stack.map((item)=>{
          return <span key={item.id}>{item.title}</span>
        })}
      </div>
      <div className="project-links">
        <a href={github || "https://github.com/FranciscoAndaur"} title="frontend">
          <FaGithubSquare className="project-icon"/>
        </a>
        <a href={github_backend || "https://github.com/FranciscoAndaur"} title="backend">
          <FaGithub className="project-icon" />
        </a>
        <a href={url || 'https://vimeo.com/bobdylan'} title="Demo">
          <FaVimeo className="project-icon" />
        </a>
      </div>
    </div>
  </article>
}

Project.propTypes = {
  title:PropTypes.string.isRequired,
  github:PropTypes.string.isRequired,
  // url:PropTypes.string.isRequired,
  description:PropTypes.string.isRequired,
  image:PropTypes.object.isRequired,
  stack:PropTypes.arrayOf(PropTypes.object).isRequired,
  bullets:PropTypes.string.isRequired,
}

export default Project
